import Vue from "vue";
import Router from "vue-router";

import Layout from "@/views/main.vue";

Vue.use(Router);

export default new Router({
	//   mode: "hash",
	//   mode: "history",
	base: process.env.BASE_URL,
	routes: [
		// {
		//   path: "/about",
		//   name: "about",
		//   // route level code-splitting
		//   // this generates a separate chunk (about.[hash].js) for this route
		//   // which is lazy-loaded when the route is visited.
		//   component: () =>
		//     import( "./views/About.vue")
		// },

		// {path: '/', redirect: '/home'},
		{
			path: "",
			component: Layout,
			redirect: "/home",
			meta: {
				idx: 0,
			},
			children: [{
				path: "/home",
				component: () =>
					import("@/views/home/index.vue")
			}]
		},
		//   产品
		{
			path: "/product",
			redirect: "/product",
			component: Layout,
			meta: {
				idx: 1,
			},
			children: [{
					path: "/product/zkzsglxt",
					name: '专科诊所管理系统',
					meta: {
						idx: 2,
					},
					component: () =>
						import("@/views/product/zkzsglxt.vue")
				},
				{
					path: "/product/jkglxt",
					name: '健康管理系统',
					meta: {
						idx: 3,
					},
					component: () =>
						import("@/views/product/jkglxt.vue")
				},
				{
					path: "/product/zymzglxt",
					name: '中医门诊管理系统',
					meta: {
						idx: 1,
					},
					component: () =>
						import("@/views/product/zymzglxt.vue")
				},
				{
					path: "/product/zhmzglxt",
					name: '综合门诊管理系统',
					meta: {
						idx: 0,
					},
					component: () =>
						import("@/views/product/zhmzglxt.vue")
				},
				{
					path: "/solution/zhyyjjfa1",
					meta: {
						nav: false,
						idx: 4,
					},
					component: () =>
						import("@/views/solution/zhyyjjfa1.vue")
				},
			]
		},
		//   解决方案
		{
			path: "/solution",
			redirect: "/solution",
			component: Layout,
			meta: {
				idx: 2,
			},
			children: [{
					path: "/solution/yljtlsxxhfa",
					meta: {
						idx: 0,
					},
					component: () =>
						import("@/views/solution/yljtlsxxhfa.vue")
				},
				{
					path: "/solution/ydyzsxxhfa",
					meta: {
						idx: 1,
					},
					component: () =>
						import("@/views/solution/ydyzsxxhfa.vue")

				},
				{
					path: "/solution/zhhlwylptfa",
					meta: {
						idx: 2,
					},
					component: () =>
						import("@/views/solution/zhhlwylptfa.vue")
				},
				{
					path: "/solution/zhyyjjfa",
					meta: {
						idx: 3,
					},
					component: () =>
						import("@/views/solution/zhyyjjfa.vue")
				},
				{
					path: "/solution/zhyyjjfa1",
					meta: {
						nav: false,
						idx: 3,
					},
					component: () =>
						import("@/views/solution/zhyyjjfa1.vue")
				},
				{
					path: "/solution/mzglxt",
					meta: {
						idx: 4,
					},
					component: () =>
						import("@/views/solution/mzglxt.vue")
				},
			]
		},
		//  定制服务
		{
			path: "/custom",
			redirect: "/custom",
			component: Layout,
			meta: {
				idx: 3,
			},
			children: [{
					path: "/custom",
					component: () =>
						import("@/views/custom/index.vue")
				},

			]
		},

		//   关于我们
		{
			path: "/about",
			//   redirect: "/about/index",
			redirect: "/about",
			component: Layout,
			meta: {
				idx: 4,
			},
			children: [{
					//   path: "/about/index",
					path: "/about",
					component: () =>
						import("@/views/aboutUs/index.vue")
					//   meta: {
					//     requireAuth: true
					//   }
				},

			]
		},

		//   客服聊天
		{
			path: "/support",
			//   redirect: "/support/index",
			redirect: "/support",
			component: Layout,
			children: [{
					//   path: "/support/index",
					path: "/support",
					component: () =>
						import( /* webpackChunkName: "support" */ "@/views/support/index.vue")
					//   meta: {
					//     requireAuth: true
					//   }
				},

			]
		},

	]
});