<template>
  <footer>
    <section class="row1">
      <div class="item item1">
        <div class="item-title">联系我们</div>
        <div class="item-content item1-content">
          <div>电话：020-28108209</div>
          <div>邮箱：vip@gzoverlook.com</div>
          <div>地址：广州市天河区天河黄埔大道西路76号富力盈隆广场405</div>
        </div>
      </div>

      <div class="item item2">
        <div class="item-content item2-content" style="border-left: 1px solid #747474">
          <div class="col">
            <div class="item-title item2-title">产品</div>
            <div class="href" @click="goDetail('/product/zhmzglxt', '1', '0')">
              综合门诊管理系统
            </div>
            <div class="href" @click="goDetail('/product/zymzglxt', '1', '1')">
              中医门诊管理系统
            </div>
            <div class="href" @click="goDetail('/product/zkzsglxt', '1', '2')">
              专科门诊管理系统
            </div>
            <div class="href" @click="goDetail('/product/jkglxt', '1', '3')">
              健康管理系统
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=0', '1', '4')"
            >
              互联网医院
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=1', '1', '5')"
            >
              医疗云HIS
            </div>
          </div>
        </div>
      </div>

      <div class="item item2">
        <div class="item-content item2-content">
          <div class="col">
            <div class="item-title item2-title" style="opacity: 0">
              产品方案
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=2', '1', '6')"
            >
              智慧电子病历系统EMR
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=3', '1', '7')"
            >
              智慧自助服务系统SSTK
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=4', '1', '8')"
            >
              智慧体检系统PEIS
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=5', '1', '9')"
            >
              智慧实验室系统LIS
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=6', '1', '10')"
            >
              智慧放射系统PACS/RIS
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhyyjjfa1?idx=7', '1', '11')"
            >
              智慧康复管理系统RIMS
            </div>
          </div>
        </div>
      </div>
      <div class="item item2">
        <div class="item-content item2-content" style="border-right: 0.001vw solid #747474">
          <div class="col">
            <div class="item-title item2-title">解决方案</div>
            <div
              class="href"
              @click="goDetail('/solution/yljtlsxxhfa', '2', '0')"
            >
              医疗集团连锁信息化方案
            </div>
            <div
              class="href"
              @click="goDetail('/solution/ydyzsxxhfa', '2', '1')"
            >
              移动云诊所信息化方案
            </div>
            <div
              class="href"
              @click="goDetail('/solution/zhhlwylptfa', '2', '2')"
            >
              智慧互联网医疗平台方案
            </div>
            <div class="href" @click="goDetail('/solution/zhyyjjfa', '2', '3')">
              智慧医院解决方案
            </div>
            <div class="href" @click="goDetail('/solution/mzglxt', '2', '4')">
              门诊管理系统解决方案
            </div>
            <div class="href" @click="goDetail('/product/jkglxt', '2', '5')">
              健康管理解决方案
            </div>
          </div>
        </div>
      </div>
      <div class="item item3">
        <div class="item-title">关注我们</div>
        <div class="item-content">
          <div class="qr-code">
            <img :src="require('@/assets/img/qcode.jpg')" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="row2">
      Copyright @2017-2022 gzoverlook版权所有 © 2022. <a style="color:#fff" href="https://beian.miit.gov.cn" target="_blank">粤ICP备17037152号</a>
    </section>
  </footer>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {
    goDetail(path, index, subIndex) {
      this.$router.push(path);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
footer {
  width: 100%;
  padding-top: 10px;
  background: #373d41; // margin-top: $footer-margin-top;
}
$border-color: #747474;
.row1 {
  // height: 180px;
  box-sizing: border-box;
  padding: 15px 0;
  border-bottom: 1px solid $border-color;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 0 12vw;
  justify-content: space-between;
  .item {
    width: 220px;
    padding: 10px 1vw;
    text-align: left;
    max-width: 100%;
    // width: 300px;
    box-sizing: border-box;
    line-height: 1.5;
    &-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-content {
      // font-size: 10px;
      font-size: 0.75rem;
      color: #999;
      div {
        margin-bottom: 5px;
      }
    }
  }
  .item1 {
    &-content {
      color: #999;
    }
  }
  .item2 {
    width: auto;
    padding: 10px 0;
    // padding: 10px 0;
    &-title {
      color: #fff;
      margin-bottom: 10px !important;
    }
    &-content {
      display: flex; // justify-content: space-around;
      // justify-content: center;
      flex-wrap: wrap;
      .col {
        padding: 0 3vw;
        .href {
          cursor: pointer;
          color: #999; // color: #fff;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .item3 {
    width: auto;
    .qr-code {
      width: 90px;
      height: 90px;
      background: #ccc;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.row2 {
  text-align: center;
  line-height: 40px; // font-size: 8px;
  font-size: 0.7rem;
  color: #fff;
  height: 40px;
  width: 100%;
}
</style>
