<template>
  <section>
    <!-- 左文右图 -->
    <section class="tsmk-wrap">
      <div
        class="tsmk-item responsive-bg"
        :style="{ backgroundImage: 'url(' + item.bg + ')' }"
      >
        <img v-if="!item.isTxtLeft" class="tsmk_img" :src="item.pic" alt="" />
        <div
          class="tsmk-item_con"
          :style="{
            marginLeft: item.marginLeft,
            marginRight: item.marginRight,
            textAlign: item.isTxtLeft ? 'right' : 'left',
          }"
          :class="{ white: item.white }"
        >
          <h4 class="tsmk-item_con_title">{{ item.name }}</h4>
          <div class="tsmk-item_con_hr"></div>
          <p class="tsmk-item_con_desc">{{ item.desc }}</p>
        </div>
        <img v-if="item.isTxtLeft" class="tsmk_img" :src="item.pic" alt="" />
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss"; // $banner-title-top: 80px;
// $swiper-height: 60vh;
// $img-offset-top: 8vh; //图片顶部距离swiper容器顶端的距离
$swiper-height: 500px;
$img-offset-top: 40px; //图片顶部距离swiper容器顶端的距离
$img-height: ($swiper-height - $img-offset-top * 2);
$right-width: 66%; // $right-width: 50%;
$left-width: 300px;
.white {
  color: #fff;
}
.whitebg {
  background: #fff;
}
// .minImg{
// 	height: $swiper-height / 2;
// }
.tsmk {
  &-wrap {
    width: 100%; // overflow: hidden;
    min-height: 500px;
  }
  &-item {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    padding: 66px 2rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    &_con {
      width: $left-width;
      box-sizing: border-box;
      text-align: right;
      &.white {
        color: #fff;
        .tsmk-item_con_hr {
          background: #fff;
        }
      }
      &_title {
        margin: 0 0 1rem 0;
        font-size: $font-size-section-title;
      }
      &_hr {
        display: inline-block;
        background: $blue;
        height: 4px; // border-radius: 100px;
        width: 30px; // margin: -5px 0 10px var(--page-margin-left-right);
      }
      &_desc {
        font-size: $font-size-section-subtitle;
        line-height: 1.5;
      }
    }
    .ml {
      margin-left: 13vw;
    }
    .tsmk_img {
      // max-width: 800px;
      max-width: 100%;
    }
  }
}
</style>
