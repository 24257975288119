<template>
  <header
    :class="{ isScrollAtTop: !isScrollAtTop || hoverMenu !== '' || isMobile }"
    @mouseleave="hoverMenu = ''"
  >
    <template v-if="!isMobile">
      <div class="header flex-middle" ref="header">
        <div class="logo flex-middle flex-1">
          <div class="logo-item"></div>
          <div class="name">眺望者科技</div>
        </div>
        <div class="flex-middle">
          <div
            class="item"
            v-for="(i, l) in menuList"
            :key="l"
            @mouseleave="i.show = false"
            @mouseover="(hoverMenu = l), (i.show = true)"
          >
            <p
              v-if="hoverMenu !== ''"
              class="title"
              :class="{ active: hoverMenu === l }"
              @click="goDetail(i.path)"
            >
              {{ i.name }}
            </p>

            <p
              v-else
              class="title"
              :class="{ active: activeMenu === l }"
              @click="goDetail(i.path)"
            >
              {{ i.name }}
            </p>

            <div class="child" v-if="i.children && i.show">
              <div class="childNav">
                <div
                  class="i"
                  v-for="(j, k) in i.children"
                  :key="k"
                  :class="{
                    active:
                      (activeMenu === l && activeSubMenu === k) ||
                      hoverSubMenu === k,
                    ddd: l == 2,
                  }"
                  @mouseover="hoverSubMenu = k"
                  @mouseleave="hoverSubMenu = ''"
                  @click.stop="goDetail(j.path)"
                >
                  <div
                    class="img"
                    :style="{
                      backgroundImage: `url(${
                        activeMenu === l && activeSubMenu === k
                          ? j.iconsPath
                          : hoverSubMenu === k
                          ? j.iconsPath
                          : j.iconPath
                      })`,
                    }"
                  ></div>

                  <p>{{ j.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn" @click="showTryPopup()">申请试用</div>
        <div class="tel">
          <p>联系电话</p>
          <p>020-28108209</p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="header mobile flex-center" ref="header">
        <img
          class="navbtn"
          src="@/assets/img/nav1.png"
          alt=""
          @click="navWrap = !navWrap"
        />
        <img
          class="logo"
          src="@/assets/img/logo-b-240.png"
          alt="logo"
          @click="goDetail('/home')"
        />
      </div>

      <div
        class="navWrap"
        v-show="navWrap"
        @click.self="navWrap = !navWrap"
      ></div>
      <div class="navMain" :class="{ active: navWrap }">
        <ul>
          <li v-for="(i, l) in menuList" :key="l">
            <p class="tit flex-middle" @click="goDetail(i.path, l)">
              <span class="flex-1">{{ i.name }}</span>
              <img
                v-if="i.children"
                :class="{ active: !i.show }"
                src="@/assets/img/down.png"
                alt=""
              />
            </p>
            <ul v-if="i.children && i.show">
              <li v-for="(k, p) in i.children" :key="p">
                <p class="" @click="goDetail(k.path)">{{ k.name }}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </template>
  </header>
</template>

<script>
import logo from "@/components/page-common/logo.vue";
export default {
  data() {
    return {
      isScrollAtTop: true, // 是否在顶部
      navWrap: false,
      navIdx: "",
      isMobile: this.getIsMobile(),
      activeMenu: 0,
      hoverMenu: "",
      activeSubMenu: 0,
      hoverSubMenu: "",
      menuList: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "产品",
          // path: "/product",
          show: false,
          children: [
            {
              name: "综合门诊管理系统",
              path: "/product/zhmzglxt",
              iconPath: require("@/assets/img/header/icon1.png"),
              iconsPath: require("@/assets/img/header/icon1_1.png"),
            },
            {
              name: "中医门诊管理系统",
              path: "/product/zymzglxt",
              iconPath: require("@/assets/img/header/icon2.png"),
              iconsPath: require("@/assets/img/header/icon2_1.png"),
            },
            {
              name: "专科诊所管理系统",
              path: "/product/zkzsglxt",
              iconPath: require("@/assets/img/header/icon3.png"),
              iconsPath: require("@/assets/img/header/icon3_1.png"),
            },
            {
              name: "健康管理系统",
              path: "/product/jkglxt",
              iconPath: require("@/assets/img/header/icon4.png"),
              iconsPath: require("@/assets/img/header/icon4_1.png"),
            },
            {
              name: "互联网医院",
              path: "/solution/zhyyjjfa1?idx=0",
              iconPath: require("@/assets/img/header/icon5.png"),
              iconsPath: require("@/assets/img/header/icon5_1.png"),
            },
            {
              name: "医疗云HIS",
              path: "/solution/zhyyjjfa1?idx=1",
              iconPath: require("@/assets/img/header/icon6.png"),
              iconsPath: require("@/assets/img/header/icon6_1.png"),
            },
            {
              name: "智慧电子病历系统EMR",
              path: "/solution/zhyyjjfa1?idx=2",
              iconPath: require("@/assets/img/header/icon7.png"),
              iconsPath: require("@/assets/img/header/icon7_1.png"),
            },
            {
              name: "智慧自助服务系统SSTK",
              path: "/solution/zhyyjjfa1?idx=3",
              iconPath: require("@/assets/img/header/icon8.png"),
              iconsPath: require("@/assets/img/header/icon8_1.png"),
            },
            {
              name: "智慧体检系统PEIS",
              path: "/solution/zhyyjjfa1?idx=4",
              iconPath: require("@/assets/img/header/icon9.png"),
              iconsPath: require("@/assets/img/header/icon9_1.png"),
            },
            {
              name: "智慧实验室系统LIS",
              path: "/solution/zhyyjjfa1?idx=5",
              iconPath: require("@/assets/img/header/icon10.png"),
              iconsPath: require("@/assets/img/header/icon10_1.png"),
            },
            {
              name: "智慧放射系统PACS/RIS",
              path: "/solution/zhyyjjfa1?idx=6",
              iconPath: require("@/assets/img/header/icon11.png"),
              iconsPath: require("@/assets/img/header/icon11_1.png"),
            },
            {
              name: "智慧康复管理系统RIMS",
              path: "/solution/zhyyjjfa1?idx=7",
              iconPath: require("@/assets/img/header/icon12.png"),
              iconsPath: require("@/assets/img/header/icon12_1.png"),
            },
          ],
        },
        {
          name: "解决方案",
          // path: "/solution",
          show: false,
          children: [
            {
              name: "医疗集团连锁信息化方案",
              path: "/solution/yljtlsxxhfa",
              iconPath: require("@/assets/img/header/icon13.png"),
              iconsPath: require("@/assets/img/header/icon13_1.png"),
            },
            {
              name: "移动云诊所信息化方案",
              path: "/solution/ydyzsxxhfa",
              iconPath: require("@/assets/img/header/icon14.png"),
              iconsPath: require("@/assets/img/header/icon14_1.png"),
            },
            {
              name: "智慧互联网医疗平台方案",
              path: "/solution/zhhlwylptfa",
              iconPath: require("@/assets/img/header/icon15.png"),
              iconsPath: require("@/assets/img/header/icon15_1.png"),
            },
            {
              name: "智慧医院解决方案",
              path: "/solution/zhyyjjfa",
              iconPath: require("@/assets/img/header/icon16.png"),
              iconsPath: require("@/assets/img/header/icon16_1.png"),
            },
            {
              name: "门诊管理系统解决方案",
              path: "/solution/mzglxt",
              iconPath: require("@/assets/img/header/icon17.png"),
              iconsPath: require("@/assets/img/header/icon17_1.png"),
            },

            {
              name: "健康管理解决方案",
              path: "/product/jkglxt",
              iconPath: require("@/assets/img/header/icon18.png"),
              iconsPath: require("@/assets/img/header/icon18_1.png"),
            },
          ],
        },
        {
          name: "定制服务",
          path: "/custom",
        },
        {
          name: "关于我们",
          path: "/about",
        },
      ],
    };
  },
  computed: {
    getIsScrollAtTop() {
      return this.$store.state.isScrollAtTop;
    },
    getIsShowSubMenu() {
      return this.$store.state.isShowSubMenu;
    },
  },
  watch: {
    $route(params) {
      this.navWrap = false;
    },
    getIsScrollAtTop(newVal) {
      //监听vuex里面isScrollAtTop的变化，动态赋值
      this.isScrollAtTop = newVal;
    },
    $route: "isLinkChange",
  },
  methods: {
    showTryPopup() {
      this.$store.commit({
        type: "SHOW_POPUP",
        procductName: "",
        versionType: "",
      });
    },

    resetActive() {
      this.isScrollAtTop = this.$store.state.isScrollAtTop;
    },
    goDetail(path, idx) {
      if(!path) return
      for (let i = 0; i < this.menuList.length; i++) {
        if (!this.isMobile) {
          this.menuList[i].show = false;
        } else {
          if (idx != i) {
            this.menuList[i].show = false;
          }
        }
      }
      if (path) {
        this.hoverMenu = "";
        this.hoverSubMenu = "";
      }
      if (idx != undefined) {
        this.menuList[idx].show = !this.menuList[idx].show;
      }
      this.$router.push(path);
    },

    showSubMenuFn(index) {
      this.menuList[index].show = !this.menuList[index].show;
      this.isScrollAtTop = false;
    },
    isLinkChange(val) {
      this.navWrap = false;
      this.activeMenu = this.$route.matched[0].meta.idx;
      this.activeSubMenu = this.$route.matched[1].meta.idx;
      if (this.$route.path == "/solution/zhyyjjfa1") {
        this.activeMenu = 1;
        this.activeSubMenu = Number(this.$route.query.idx) + 4;
      }
    },
  },
  components: {
    logo,
  },
  created() {},
  mounted() {
    this.isLinkChange();
    // PC端 移动端 跳转
  },
  activated() {
    this.resetActive();
  },
};
</script>

<style lang="scss" scoped>
header {
  z-index: 99;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  background: transparent;
  color: #fff;

  * {
    box-sizing: border-box;
  }
  &.isScrollAtTop {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    background: #fff;
    color: #052b41;
    .header {
      .logo .logo-item {
        background-image: url("../../assets/img/logo-b-240.png");
      }
      .btn {
        border-image: linear-gradient(0deg, #345cff, #345cff) 10 10;
        background: linear-gradient(-90deg, #6db9f7 0%, #345cff 100%);
        border: 0;
        line-height: 36px;
      }
      .tel {
        color: #345cff;
      }
      .item .title.active {
        color: #345cff;
        &::before {
          background: #345cff;
        }
      }
    }
  }
  .header {
    height: 60px;
    max-width: 1350px;
    margin: 0 auto;

    &.mobile {
      height: 50px;
      .logo {
        height: 30px;
      }
      .navbtn {
        position: absolute;
        left: 4vw;
        width: 28px;
      }
    }
    .logo {
      .name {
        font-size: 24px;
        margin-left: 0.5rem;
      }
      .logo-item {
        width: 60px;
        height: 35px;
        background-size: cover;
        background-position: center;
        background-image: url("../../assets/img/logo-w-240.png");
      }
    }
    .item {
      .title {
        padding: 0 20px;
        display: block;
        position: relative;
        font-size: 16px;
        line-height: 60px;
        margin: 0;
        cursor: pointer;
        &:hover {
          color: #345cff;
        }
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          bottom: 12px;
          height: 4px;
          width: 28%;
          background: transparent;
          border-radius: 100px;
        }
        &.active {
          &::before {
            background: #fff;
          }
          // color: rgba(47, 155, 243, 0.618);
        }
      }
      .child {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 10px 0;
        background: #fff;
        box-shadow: 0px 20px 20px -20px rgba(0, 0, 0, 0.4);

        .childNav {
          flex-wrap: wrap;
          margin: 0 auto;
          display: flex;
          max-width: 1320px;
          padding-top: 4px;
        }
        .i {
          width: 220px;
          cursor: pointer;
          text-align: center;
          font-size: 16px;
          width: 220px;
          display: block;
          padding: 25px 0;
          &.ddd {
            width: auto;
            padding: 25px 32px;
          }
          &.active {
            color: #345cff;
          }
          .img {
            height: 52px;
            background-size: auto 52px;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 10px;
          }
          p {
            margin: 0;
          }
        }
      }
    }
    .btn {
      width: 112px;
      height: 36px;
      line-height: 34px;
      border-radius: 18px;
      text-align: center;
      border: 1px solid #fff;
      // border-image: linear-gradient(0deg, #345cff, #345cff) 10 10;
      // background: linear-gradient(-90deg, #6db9f7 0%, #3d72f0 100%);
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      margin-left: 10px;
    }
    .tel {
      margin-left: 30px;
      p {
        font-size: 10px;
        margin: 0;
        &:last-child {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  .navWrap {
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    z-index: 99;
  }
  .navMain {
    background: #fff;
    position: fixed;
    width: 70%;
    bottom: 0;
    overflow: auto;
    top: 50px;
    left: -100%;
    z-index: 99;
    transition: all 0.3s;

    &.active {
      left: 0;
    }
    p {
      margin: 0;
      font-size: 14px;
    }
    ul {
      padding: 0 1rem;
      li {
        line-height: 50px;
        border-bottom: 1px solid #ccc;
        .tit {
          padding: 0.3rem 0;
          font-size: 16px;
          color: #333;
          margin: 0;
          img {
            width: 17px;

            &.active {
              transform: rotate(-90deg);
            }
          }
        }
        ul {
          padding: 0 0.3rem;

          li {
            border: 0;
            line-height: 40px;
          }
        }
      }
      .active {
        color: #345cff;
      }
    }
  }
}
</style>
