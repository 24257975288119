<template>
	<div class="programmer" @click="goSupportPage">
		<img :src="require('@/assets/img/support/girl.png')" alt="">
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		computed: {},
		watch: {},
		methods: {
			goSupportPage(){
				this.$router.push('/support')
			}
		},
		// watch: {
		// 	getIsScrollAtTop(newVal, oldVal) { //监听vuex里面isScrollAtTop的变化，动态赋值
		// 		this.isScrollAtTop = newVal;
		// 	},
		// },
		created() {},
		mounted() {},
		activated() {}
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/variable.scss";
	.programmer{
		position: fixed;
		bottom: 10vh;
		left: $page-margin-left-right / 5;
		left: calc(var(--page-margin-left-right) / 5);
		width: 200px;
		z-index: 99;
		img{
			@include responsive-img();
			cursor: pointer;
		}
	}
</style>
