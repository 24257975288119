
<template>
  <div class="container" id="container">
    <!-- 顶部导航条 -->
    <home-header
      ref="homeHeaderComponent"
      v-if="isUpdateHeaderComponent"
    ></home-header>
    <!-- 网站内容 -->
    <main class="main" id="scrollarea">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>
    <!-- 底部版权栏 -->
    <home-footer @changeNav="resetActiveNav"></home-footer>
    <!-- 客服程序员 -->
    <!-- <programmer></programmer> -->

    <popup v-if="$store.state.showFormPopup"></popup>
  </div>
</template>

<script>
/* eslint-disable */
import HomeHeader from "@/components/page-common/HomeHeader.vue";
import HomeFooter from "@/components/page-common/HomeFooter.vue";
import programmer from "@/components/page-common/programmer.vue";
import popup from "@/components/page-common/popup.vue";
export default {
  components: {
    "home-header": HomeHeader,
    "home-footer": HomeFooter,
    programmer,
    popup,
  },
  data() {
    return {
      isUpdateHeaderComponent: true,
    };
  },
  watch: {
    // $route: function (to) {
    //   this.reload(); //为了切换路由时候，导航条变回透明的
    // },
  },
  methods: {
    // 如果是刷新某个子组件，则可以通过v-if指令实现。我们知道，当v-if的值发生变化时，组件都会被重新渲染一遍。因此，利用v-if指令的特性，可以达到强制刷新组件的目的
    reload() {
      // 移除组件
      this.isUpdateHeaderComponent = false;
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.isUpdateHeaderComponent = true;
      });
    },
    resetActiveNav() {
      this.$refs.homeHeaderComponent.resetActive();
    },
    handleScroll() {
      // scrollTop是滚动条顶部距离页面顶部的偏移距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var bannerHeight = 1500;
      if (scrollTop < bannerHeight) {
        this.$store.commit("SET_IS_SCROLL_IN_BANNER", true);
      } else {
        this.$store.commit("SET_IS_SCROLL_IN_BANNER", false);
      }
      let rowHeight = 350;
      // 判断滚动条是否在顶部
      if (scrollTop < 150) {
        this.$store.commit("SET_IS_SCROLL_AT_TOP", true);
      } else if (scrollTop >= 150 && scrollTop < 150 + rowHeight * 1) {
        this.$store.commit("SET_IS_SCROLL_AT_TOP", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW1", true);
        this.$store.commit("SET_IS_SCROLL_AT_ROW2", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW3", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW4", false);
      } else if (
        scrollTop >= 150 + rowHeight * 1 &&
        scrollTop < 150 + rowHeight * 2
      ) {
        this.$store.commit("SET_IS_SCROLL_AT_TOP", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW1", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW2", true);
        this.$store.commit("SET_IS_SCROLL_AT_ROW3", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW4", false);
      } else if (
        scrollTop >= 150 + rowHeight * 2 &&
        scrollTop < 150 + rowHeight * 3
      ) {
        this.$store.commit("SET_IS_SCROLL_AT_TOP", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW1", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW2", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW3", true);
        this.$store.commit("SET_IS_SCROLL_AT_ROW4", false);
      } else if (
        scrollTop >= 150 + rowHeight * 3 &&
        scrollTop < 150 + rowHeight * 4
      ) {
        this.$store.commit("SET_IS_SCROLL_AT_TOP", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW1", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW2", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW3", false);
        this.$store.commit("SET_IS_SCROLL_AT_ROW4", true);
      } else {
        // this.$store.commit("SET_IS_SCROLL_AT_ROW4", true)
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    if (this.getIsMobile()) {
      $("#container").css("paddingTop", 50);
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  // background: red;
  // overflow: hidden;
  .main {
    min-height: 100vh;
    width: 100%;
  }
}
</style>