<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style lang="scss">
@import "@/assets/scss/variable.scss";
#app {
  font-family: Helvetica, Tahoma, Arial, STXihei, "华文细黑", "Microsoft YaHei",
    "微软雅黑", Heiti, "黑体", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}
</style>
<script>
import Vue from "vue";

Vue.mixin({
  /* 全局方法 */
  methods: {
    getIsMobile() {
      var is_mobi =
        navigator.userAgent
          .toLowerCase()
          .match(
            /(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
          ) != null;
      if (is_mobi) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {},
});
// import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  name: "app",
  computed: {},
  watch: {
    $route: "isLinkChange",
  },
  filters: {},

  created() {
    let that = this;
  },
  methods: {
    isLinkChange: function () {
      let that = this;
      // console.log("改变了导航:", this.$route);
      document.title = "眺望者科技-专注于医疗健康信息化建设";

      // $('[name="Keywords"]').attr(
      //   "content",
      //   "综合门诊管理系统，专科门诊管理系统，中医门诊管理系统，健康管理系统，his系统，医院综合系统，医院门诊管理系统"
      // );
      // $(doucment).find("meta[name='Keywords']").attr("contents",'');
      if (this.$route.name) {
        // document.title = this.$route.name;
        let head = document.getElementsByTagName("head");
        let meta = document.createElement("meta");
        meta.content = this.$route.name;
        meta.name = "Keywords";
        head[0].appendChild(meta);
      }
      //显示底部导航
    },
  },
  mounted() {
    let that = this;
  },
  destroyed() {},
};
</script>
