import 'babel-polyfill'
require("babel-polyfill");

import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'

import Jquery from 'jquery'
window.$ = Jquery
import BaiduMap from 'vue-baidu-map'
Vue.config.productionTip = false

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);


// 引入网站图标
// require("../public/favicon.ico");

import "normalize.css/normalize.css"; // 初始化样式
import "@/assets/scss/reset.scss"; // 初始化样式（补充）


import "@/router/index.js" // 路由的逻辑控制


// 引入swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import tsmkItem from "@/components/page-common/tsmkItem.vue";
Vue.component('tsmk-item', tsmkItem)


import productVersion from "@/components/page-common/ProductVersion.vue";
Vue.component('product-version', productVersion)


import banner from "@/components/page-common/banner.vue";
Vue.component('banner', banner)

import lxwm from "@/components/lxwm.vue";
Vue.component('lxwm', lxwm)


Vue.use(BaiduMap, {
	ak: 'LIKj90aHd5pWpaMinDs6gRlE' //这个地方是官方提供的ak密钥
})

// If used in nuxt.js/ssr, you should keep it only in browser build environment
// if (process.browser) {
// 	const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')
// 	Vue.use(VueAwesomeSwiper)
// }


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')