import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showFormPopup: false, // 是否显示试用弹窗
    procductName: '',
    versionType: '',

	isScrollAtTop: true,
	
	isShowSubMenu: true, //是否展示二级菜单

    isScrollAtTop: true,
    isScrollAtRow1: false,
    isScrollAtRow2: false,
    isScrollAtRow3: false,
    isScrollAtRow4: false,
    isScrollAtRow4: false,
    isScrollInBanner: true, //滚动条是否在banner区域内
  },
  mutations: {
    SHOW_POPUP: (state, payload) => {
      state.showFormPopup = true
      state.procductName = payload.procductName
      state.versionType = payload.versionType
    },
    HIDE_POPUP: (state) => {
      state.showFormPopup = false
      state.procductName = ''
      state.versionType = ''
    },
    SET_IS_SHOW_SUB_MEMU: (state, isShowSubMenu) => {
      state.isShowSubMenu = isShowSubMenu;
    },
    SET_IS_SCROLL_AT_TOP: (state, isScrollAtTop) => {
      //滚动条是否在顶部，用于变色
      state.isScrollAtTop = isScrollAtTop;
    },
    SET_IS_SCROLL_AT_ROW1: (state, isScrollAtRow1) => {
      //滚动条是否在row1
      state.isScrollAtRow1 = isScrollAtRow1;
    },
    SET_IS_SCROLL_AT_ROW2: (state, isScrollAtRow2) => {
      //滚动条是否在row2
      state.isScrollAtRow2 = isScrollAtRow2;
    },

    SET_IS_SCROLL_AT_ROW3: (state, isScrollAtRow4) => {
      //滚动条是否在row3
      state.isScrollAtRow3 = isScrollAtRow4;
    },
    SET_IS_SCROLL_AT_ROW4: (state, isScrollAtRow4) => {
      //滚动条是否在row4
      state.isScrollAtRow4 = isScrollAtRow4;
    },
    SET_IS_SCROLL_IN_BANNER: (state, isScrollInBanner) => {
      //滚动条是否在row4
      state.isScrollInBanner = isScrollInBanner;
    },
  },
  actions: {}
});
