<template>
  <section class="lxwm">
    <h1>联系我们</h1>
    <button class="flex-center" @click="tel()">
      <img src="@/assets/img/tel.png" alt="" />
      <a>020-28108209</a>
    </button>
    <a id="tel" style="display: none" href="tel:020-28108209">020-28108209</a>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["hasBlueBg"],
  methods: {
    tel() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      console.log(isAndroid, isiOS);
      if (this.getIsMobile()) {
        if (isAndroid) {
          this.$Modal.confirm({
            title: " 温馨提示",
            content: "是否确认拨号？",
            onOk: () => {
              $("#tel").click();
              document.getElementById("tel").click();
            },
            onCancel: () => {},
          });
        }
        if (isiOS) {
          $("#tel").click();

          document.getElementById("tel").click();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lxwm {
  background-size: cover;
  text-align: center;
  overflow: hidden;
  padding: 64px 0;
  line-height: 1;
  font-size: 28px;

  background-position: center;
  background-image: url("../assets/img/lxwm.png");
  h1 {
    // font-weight: bold;
    color: #ffffff;
    margin: 0;
    font-size: 32px;
    margin-bottom: 40px;
  }
  button {
    background: #fff;
    height: 64px;
    width: 300px;
    border-radius: 32px;
    margin: 0 auto;
    color: #4073ff;
    letter-spacing: -0.5px;
    img {
      width: 24px;
      margin-right: 10px;
    }
    a {
      color: #4073ff;
    }
  }
}
</style>
