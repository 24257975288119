import Vue from "vue";
import axios from "axios";


// 创建axios实例
const service = axios.create({
	//   baseURL: 'http://120.24.84.219:112', // api的base_url，不开代理的话直接用这个
	//   baseURL: Vue.prototype.BASE_URL, // api的base_url，不开代理的话直接用这个
	// baseURL: configObj.BASE_URL, // api的base_url，不开代理的话直接用这个
	baseURL: "/api", // 开了代理
	timeout: 5000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
	config => {
		// if (store.getters.token) {
		//     config.headers.common[`Authorization`] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
		// }

		// config.headers.common[`Access-Control-Allow-Origin`] = '*'
		return config;
	},
	error => {
		// Do something with request error
		console.log(error); // for debug
		Promise.reject(error);
	}
);

// 状态码错误信息
// const codeMessage = {
//   200: "服务器成功返回请求的数据。",
//   201: "新建或修改数据成功。",
//   202: "一个请求已经进入后台排队（异步任务）。",
//   204: "删除数据成功。",
//   400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
//   401: "用户没有权限（令牌、用户名、密码错误）。",
//   403: "用户得到授权，但是访问是被禁止的。",
//   404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
//   406: "请求的格式不可得。",
//   410: "请求的资源被永久删除，且不会再得到的。",
//   422: "当创建一个对象时，发生一个验证错误。",
//   500: "服务器发生错误，请检查服务器。",
//   502: "网关错误。",
//   503: "服务不可用，服务器暂时过载或维护。",
//   504: "网关超时。"
// };

// respone拦截器
service.interceptors.response.use(
	response => {
        /**
         * code为非0是抛错 可结合自己业务进行修改
         */
		// if (response.data.code !== 200) {
		//   switch (response.data.code) {
		//     case 403:
		//       MessageBox.confirm(
		//         "你已被登出，可以取消继续留在该页面，或者重新登录",
		//         "确定登出",
		//         {
		//           confirmButtonText: "重新登录",
		//           cancelButtonText: "取消",
		//           type: "warning"
		//         }
		//       ).then(() => {
		//         store.dispatch("FedLogOut").then(() => {
		//           location.reload(); // 为了重新实例化vue-router对象 避免bug
		//         });
		//       });
		//       break;
		//     default:
		//       console.log("我走到了这里", res);
		//       Message({
		//         message: res,
		//         type: "error",
		//         duration: 10 * 1000
		//       });
		//   }

		//   return Promise.reject("error");
		// } else {
		//   return response;
		// }
		// alert("我走到了res拦截器的response里，接下来会执行我们写的请求的回调");
		return response;
	},
	error => {
		// alert('我走到了res拦截器的error里，我们写的请求的回调不会被执行！！')
		// 这里获取状态码
		// let errorObj = JSON.parse(JSON.stringify(error));
		// console.log("出错了" + error); // for debug
		// switch (errorObj) {
		//     case 404:
		//     case 401:
		//         // Confirm(`状态码${statusCode}，令牌已失效或令牌错误，请重新登录`, "重新登录", {
		//         //   confirmButtonText: "确定",
		//         //   cancelButtonText: "取消",
		//         //   type: "warning"
		//         // }).then(() => {
		//         //   this.$message({
		//         //     type: "success",
		//         //     message: "登录成功!"
		//         //   });
		//         //   removeToken(); //把已经过期的token清空
		//         //   location.reload(); // 强制刷新，为了让它跳去执行permission.js没拿到token然后跳转login页的逻辑（61行）
		//         // });
		//         Message({
		//             message: "您没有该权限，请联系系统管理员",
		//             type: "error",
		//             duration: 5 * 1000
		// 		});
		// 		router.push('/401')
		//         // removeToken(); //把已经过期的token清空
		//         // location.reload(); // 强制刷新，为了让它跳去执行permission.js没拿到token然后跳转login页的逻辑（61行）
		//         break;
		//     case 403:
		//         Message({
		//             message: "您没有该页面的访问权限，请联系系统管理员",
		//             type: "error",
		//             duration: 5 * 1000
		//         });
		//         break;
		// 	default:
		// 		// alert(JSON.parse(JSON.stringify(error)).response.data.msg)
		// 		console.log(JSON.parse(JSON.stringify(error)));
		//         break;
		// }

		return Promise.reject(error);
	}
);

export default service;