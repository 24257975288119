<template>
	<div class="logo flex-middle">
		<img class="logo-item" v-if="!isScrollAtTop" src="@/assets/img/logo-b-240.png" alt="logo" @click="goDetail('/home')" />
		<img class="logo-item" v-if="isScrollAtTop " src="@/assets/img/logo-w-240.png" alt="logo" @click="goDetail('/home')" />
		<div class="name">
			<h1>眺望者科技</h1>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isScrollAtTop: true,
				isShowSubMenu: true, //是否展示二级菜单
			}
		},
		methods: {
			goDetail(path) {
				this.$router.push(path);
				sessionStorage.setItem('activeMenu', '0');
			}
		},
		computed: {
			getIsScrollAtTop() {
				return this.$store.state.isScrollAtTop;
			},
			getIsShowSubMenu() {
				return this.$store.state.isShowSubMenu;
			},
		},
		watch: {
			getIsScrollAtTop(newVal) { //监听vuex里面isScrollAtTop的变化，动态赋值
				this.isScrollAtTop = newVal;
			},
			getIsShowSubMenu(newVal) { //监听vuex里面isScrollAtTop的变化，动态赋值
				this.isShowSubMenu = newVal;
				
			},
		},
		created() {},
		mounted() {},
		activated() {}
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/variable.scss"; 
	.name {
		line-height: 1rem;
		text-align: left;
		white-space: nowrap;
		// @include Vcenter();
		// left: 70px;
		h1 {
			font-size: 24px;
			margin-left: .5rem;
		}
	}
	.logo-item{
		width: 60px;
	}
</style>
