import axios from '@/api/index.js'
import qs from 'qs'


// 获取验证码
export const getValidCode = params => { 
    return axios.get('http://websiteapi.gzoverlook.com/api/Home/SendValidCode', { params: params.query }).then(res => res.data)
}


export const custom = params => {// 更新密码（我也不知道带query参数为啥就要用qs转一下，反正这里的qs.stringify不能省略就是了）
    return axios.post('http://websiteapi.gzoverlook.com/api/Home/AddRequirementForm', params.data).then(res => res.data)
}



export const tryUse = params => {// 更新密码（我也不知道带query参数为啥就要用qs转一下，反正这里的qs.stringify不能省略就是了）
    return axios.post('http://websiteapi.gzoverlook.com/api/Home/AddTrailForm', params.data).then(res => res.data)
}