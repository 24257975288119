<template>
  <section class="popup">
    <div class="black-popup" @touchmove.prevent @click.stop="close"></div>
    <form action="" class="form">
      <div class="form-title flex-middle">
        <p class="fl">眺望者科技</p>
        <div class="fr">
          <p>服务热线</p>
          <p style="font-size: 20px">020-28108209</p>
        </div>
      </div>
      <div class="form-row1">
        <input
          class="form-row1-input form-row1-input2 form-row1-item"
          type="text"
          placeholder="*申请机构名称"
          v-model="orgName"
        />
        <input
          class="form-row1-input form-row1-item"
          type="text"
          placeholder="*申请人姓名"
          v-model="contactPerson"
        />
        <input
          class="form-row1-input form-row1-item"
          type="text"
          placeholder="*手机号码"
          v-model="contactPhone"
        />
        <div class="form-row1-yzm">
          <input
            v-model="validCode"
            class=""
            type="text"
            placeholder="*验证码"
          />
          <span
            class="yzm"
            @click="getCode"
            :class="{ 'form-btn-disabled': getCodeDisabled }"
            >{{ getCodeText }}</span
          >
        </div>
        <input
          class="form-row1-input form-row1-input1 form-row1-item"
          type="text"
          placeholder="邮箱(选填)"
          v-model="email"
        />
        <textarea
          name=""
          placeholder="备注(选填)"
          id=""
          cols="30"
          rows="5"
          v-model="memo"
          class="form-textarea"
        ></textarea>
        <button class="primary-btn form-btn btn-jianbian" @click="custom">
          申请试用
        </button>
        <button
          class="primary-btn form-btn btn-jianbian"
          @click="close"
          style="background: none; color: #2f9bf3; margin-top: 1rem"
        >
          关闭
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { getValidCode, tryUse } from "@/api/summit.js";
export default {
  data() {
    return {
      getCodeText: "点击获取验证码",
      getCodeDisabled: false,
      contactPhone: null,
      contactPerson: null,
      memo: null,
      validCode: null,
      email: null,
      orgName: null,
    };
  },
  methods: {
    close() {
      this.$store.commit({
        type: "HIDE_POPUP",
      });
    },
    getCode() {
      if (!this.contactPhone) {
        this.$Message.info("请先输入手机号");
        return;
      }
      let params = {
        query: {
          mobile: this.contactPhone,
        },
      };
      getValidCode(params).then((res) => {
        let wait = 60;
        let that = this;
        function time() {
          if (wait == 0) {
            that.getCodeText = "点击获取验证码";
            that.getCodeDisabled = false;
            wait = 60;
          } else {
            that.getCodeDisabled = true;
            that.getCodeText = `重新发送( ${wait}s )`;
            wait--;
            setTimeout(function () {
              time();
            }, 1000);
          }
        }
        time();
      });
    },
    custom(e) {
      e.preventDefault();
      if (!this.contactPhone) {
        this.$Message.info("请先输入手机号");
        return;
      }
      if (!this.validCode) {
        this.$Message.info("请先输入验证码");
        return;
      }
      if (!this.contactPerson) {
        this.$Message.info("请先输入联系人姓名");
        return;
      }
      let params = {
        data: {
          ContactPerson: this.contactPerson,
          ContactPhone: this.contactPhone,
          Memo: this.memo,
          ValidCode: this.validCode,
          OrgName: this.orgName,
          Email: this.email,
          ProcductName: this.$store.state.procductName,
          VersionType: this.$store.state.versionType,
        },
      };
      tryUse(params).then((res) => {
        // if (res.code == 0) {
          this.$Message.success({
            background: true,
            content: "提交申请成功！",
          });
          this.contactPerson = "";
          this.contactPhone = "";
          this.memo = "";
          this.validCode = "";
          this.orgName = "";
          this.email = "";
          this.close();
        // } else {
        //   this.$Message.error({
        //     background: true,
        //     content: res.msg,
        //   });
        // }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}
.black-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
$form-height: 500px;
$form-offset-top: 140px; // $form-height: 45vh;
// $form-offset-top: 15vh;
.form {
  width: 450px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: 1000;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 50px 9px rgba(163, 153, 153, 0.3);
  padding: 0;
  position: absolute;
  @include HVcenter();
  // left: 50%;
  // top: 80px;
  // margin-left: -225px;

  * {
    box-sizing: border-box;
  }
  border: none;
  &-title {
    background: linear-gradient(
      96deg,
      rgba(72, 108, 255, 1) 0%,
      rgba(109, 129, 252, 1) 100%
    );
    color: #fff;
    padding: 0 10px;
    justify-content: space-between;
    p {
      padding: 0;
      margin: 0;
    }
    .fl {
      float: left;
      font-size: 26px;
      // text-align: center;
      // width: 200px;
      line-height: 60px;
    }
    .fr {
      font-size: 14px;
      float: right;
      text-align: left;
      padding-left: 10px;
      // width: 200px;
      position: relative;
      margin-top: 6px;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0px;
        bottom: 4px;
        background: #fff;
        width: 1px;
      }
    }
  }
  &-row1 {
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 40px;
    &-yzm {
      position: relative;
      margin-bottom: 15px;
      input {
        border: none;
        height: 2.5rem;
        width: 100%;
        text-indent: 1rem;
        border-radius: 3px;
        border: 1px solid #ddd;
        &:focus {
          border: 1px solid $blue;
          box-shadow: 0 0 5px rgba($blue, 0.5);
        }
      }
      .yzm {
        font-size: 0.7rem;
        padding-left: 1rem;
        border-left: 1px solid #ddd;
        cursor: pointer;
        color: $blue;
        position: absolute;
        display: inline-block;
        @include Vcenter();
        min-width: 5rem;
        right: 1rem;
      }
    }
    &-input {
      border: none;
      height: 2.5rem;
      display: block;
      width: 100%;
      margin-bottom: 15px;
      text-indent: 1rem;
      border-radius: 3px;
      border: 1px solid #ddd;
      &:focus {
        border: 1px solid $blue;
        box-shadow: 0 0 5px rgba($blue, 0.5);
      }
    }
  }
  &-row2 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 1rem auto;
    &-input {
      border: none;
      height: 2.5rem;
      text-indent: 1rem;
      border-radius: 3px;
      border: 1px solid #ddd;
      &1 {
        width: 30%;
      }
      &2 {
        width: 64.8%;
      }
      &:focus {
        border: 1px solid $blue;
        box-shadow: 0 0 5px rgba($blue, 0.5);
      }
    }
  }
  &-textarea {
    border: none;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 1rem;
    box-sizing: border-box;
    margin: 0 auto 1rem auto;
    display: inline-block;
    min-height: 1rem;
    outline: none;
    width: 100%;
    max-width: 100%;
    &:focus {
      border: 1px solid $blue;
      box-shadow: 0 0 5px rgba($blue, 0.5);
    }
  }
  &-btn {
    display: block;
    margin: 0 auto;
    color: #fff;
    width: 100%;
    background: linear-gradient(
      -78deg,
      rgba(109, 129, 252, 1) 0%,
      rgba(72, 108, 255, 1) 100%
    );
    text-align: center;
    line-height: 34px;
    border-radius: 4px;
    &:hover {
      background: linear-gradient(
        -78deg,
        rgba(109, 129, 252, 1) 0%,
        rgba(72, 108, 255, 1) 100%
      );
    }
    &-disabled {
      pointer-events: none;
    }
  }
}
</style>
