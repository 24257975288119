<template>
  <section>
    <section class="section row2" @scroll="console.log('gundaosi')">
      <div class="section-title">
        <!-- :class="[isScrollAtTop ?  '' : 'animation-leftin']" -->
        <h1
          class="section-title_black"
          v-html="justHas2Version ? '版本服务' : '版本服务'"
        ></h1>
        <p class="section-subtitle_black">{{ subTitle }}</p>
      </div>
      <div class="row-wrap">
        <div
          class="row2-item"
          :class="justHas2Version ? 'version-2-item' : 'version-3-item'"
          v-for="(item, index) in currVersion.versionArr"
          :key="index"
        >
          <div class="row2-item-header">
            <h3 class="row2-item-title">{{ item }}</h3>
            <div class="row2-item-hr"></div>
            <button
              class="primary-btn row2-item-btn"
              @click="showTryPopup(item)"
            >
              申请试用
            </button>
          </div>
          <div
            class="feature"
            :class="{ feature_disable: !cItem.enable[index] }"
            v-for="(cItem, cIndex) in currVersion.content"
            :key="cIndex"
          >
            <div class="feature-name">
              <h4>{{ cItem.featureName }}</h4>
            </div>
            <div
              class="feature-item"
              v-for="(ccItem, ccIndex) in cItem.content"
              :key="ccIndex"
              @mouseover="
                (currentItemSubSubIndex = ccIndex),
                  (currentItemSubIndex = cIndex),
                  (currentItemIndex = index)
              "
              @mouseenter="
                (currentItemSubSubIndex = ccIndex),
                  (currentItemSubIndex = cIndex),
                  (currentItemIndex = index)
              "
              @mouseout="
                (currentItemSubSubIndex = null),
                  (currentItemSubIndex = null),
                  (currentItemIndex = null)
              "
            >
              <span>{{ ccItem.name }}</span>
              <span v-if="ccItem.chooseDesc">{{ ccItem.chooseDesc }}</span>
              <span v-if="!ccItem.chooseDesc" style="">✔</span>
              <div
                class="popup"
                v-if="ccItem.memo"
                :class="{
                  'popup-show':
                    currentItemSubSubIndex == ccIndex &&
                    currentItemSubIndex == cIndex &&
                    currentItemIndex == index,
                }"
              >
                {{ ccItem.memo }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    subTitle: {
      type: String,
      required: true,
    },
    justHas2Version: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    procductName: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentItemIndex: null,
      currentItemSubIndex: null,
      currentItemSubSubIndex: null,
      isScrollAtTop: true,
      versionItemList: [
        {
          versionArr: ["标准版", "专业版", "连锁版"],
          content: [
            {
              featureName: "基础功能",
              enable: [true, true, true],
              content: [
                {
                  name: "8大模块",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "接口对接",
              enable: [true, true, true],
              content: [
                {
                  name: "微信，支付宝，医保，仪器",
                  memo: "",
                  chooseDesc: null,
                },
                {
                  name: "身份证读卡器等",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "回访套件",
              enable: [true, true, true],
              content: [
                {
                  name: "短信",
                  memo: "",
                  chooseDesc: null,
                },
                {
                  name: "电话录音盒",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "拓展功能",
              enable: [false, true, true],
              content: [
                {
                  name: "微商城",
                  memo: "",
                  chooseDesc: null,
                },
                {
                  name: "语音叫号",
                  memo: "",
                  chooseDesc: null,
                },
                {
                  name: "候诊上屏",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "移动端",
              enable: [false, true, true],
              content: [
                {
                  name: "微信公众号",
                  memo: "",
                  chooseDesc: null,
                },
                {
                  name: "微信小程序",
                  memo: "",
                  chooseDesc: null,
                },
                {
                  name: "APP",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "营销套餐",
              enable: [false, true, true],
              content: [
                {
                  name: "公众号小程序申请",
                  memo: "",
                  chooseDesc: "免费",
                },
                {
                  name: "公众号运营",
                  memo: "",
                  chooseDesc: "可选",
                },
                {
                  name: "文案策划",
                  memo: "",
                  chooseDesc: "可选",
                },
                {
                  name: "网络营销",
                  memo: "",
                  chooseDesc: "可选",
                },
              ],
            },
            {
              featureName: "品牌形象",
              enable: [false, true, true],
              content: [
                {
                  name: "品牌策划",
                  memo: "",
                  chooseDesc: "可选定制",
                },
                {
                  name: "企业logo",
                  memo: "",
                  chooseDesc: "可选定制",
                },
                {
                  name: "企业官网",
                  memo: "",
                  chooseDesc: "可选定制",
                },
              ],
            },
            {
              featureName: "集团管理",
              enable: [false, false, true],
              content: [
                {
                  name: "总部门店连锁运营",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "远程诊疗",
              enable: [false, false, true],
              content: [
                {
                  name: "图文，视频远程会诊",
                  memo: "",
                  chooseDesc: "可选",
                },
              ],
            },
            {
              featureName: "上门服务",
              enable: [true, true, true],
              content: [
                {
                  name: "上门安装，培训",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "定制服务",
              enable: [true, true, true],
              content: [
                {
                  name: "按需调整定制",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
          ],
        },
        {
          versionArr: ["标准版", "综合版"],
          content: [
            {
              featureName: "基础功能",
              enable: [true, true],
              content: [
                {
                  name: "8大模块",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "接口对接",
              enable: [true, true],
              content: [
                {
                  name: "检测仪器对接",
                  memo: "",
                  chooseDesc: "可定制",
                },
              ],
            },
            {
              featureName: "回访套件",
              enable: [true, true],
              content: [
                {
                  name: "短信",
                  memo: "",
                  chooseDesc: null,
                },
                {
                  name: "电话录音盒",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "拓展功能",
              enable: [false, true],
              content: [
                {
                  name: "微商城",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "移动端",
              enable: [false, true],
              content: [
                {
                  name: "患者微信公众号和小程序",
                  memo: "",
                  chooseDesc: "可二选一",
                },
                {
                  name: "健康APP",
                  memo: "",
                  chooseDesc: "可定制",
                },
              ],
            },
            {
              featureName: "营销套餐",
              enable: [false, true],
              content: [
                {
                  name: "公众号小程序申请",
                  memo: "",
                  chooseDesc: "免费",
                },
                {
                  name: "公众号运营",
                  memo: "",
                  chooseDesc: "可选",
                },
                {
                  name: "文案策划",
                  memo: "",
                  chooseDesc: "可选",
                },
                {
                  name: "网络营销",
                  memo: "",
                  chooseDesc: "可选",
                },
              ],
            },
            {
              featureName: "品牌形象",
              enable: [false, true],
              content: [
                {
                  name: "品牌策划",
                  memo: "",
                  chooseDesc: "可选定制",
                },
                {
                  name: "企业logo",
                  memo: "",
                  chooseDesc: "可选定制",
                },
                {
                  name: "企业官网",
                  memo: "",
                  chooseDesc: "可选定制",
                },
              ],
            },
            {
              featureName: "上门服务",
              enable: [true, true],
              content: [
                {
                  name: "上门安装，培训",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
            {
              featureName: "定制服务",
              enable: [true, true],
              content: [
                {
                  name: "按需调整定制",
                  memo: "",
                  chooseDesc: null,
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    showTryPopup(versionType) {
      this.$store.commit({
        type: "SHOW_POPUP",
        procductName: this.procductName,
        versionType: versionType,
      });
    },
  },
  computed: {
    currVersion: function () {
      if (this.name == "zymz" || this.name == "zhmz") {
        this.versionItemList[this.type].versionArr = [
          "标准版",
          "综合版",
          "连锁版",
        ];
        this.versionItemList[this.type].content[0].content[0].name = "9大模块";
      }
      return this.versionItemList[this.type];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss"; // $banner-title-top: 80px;
$row-content-top-offset: 0; // 本页面公用的动画
.row-wrap {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1370px;
  padding-top: $row-content-top-offset;
}
.version-3-item {
  width: 400px;
  margin: 0 20px;
}
.version-2-item {
  width: 400px;
  margin: 0 20px;
}
.row2 {
  &-item {
    margin-bottom: 10vh; // border: 1px solid rgba(6, 20, 31, 0);
    box-shadow: 0px 0px 8px 0px rgba(179, 178, 178, 0.35);
    box-sizing: border-box;
    text-align: center; // overflow: hidden;
    border: 1px solid transparent;
    &-header {
      width: 100%; // height: 16vh;
      height: 140px;
      padding-top: 2vh; // overflow: hidden;
    }
    &:nth-child(1) .row2-item-header {
      background: #aeb0ff;
    }
    &:nth-child(2) .row2-item-header {
      background: #9295ff;
    }
    &:nth-child(3) .row2-item-header {
      background: #686cff;
    }
    &-title {
      color: #fff; // font-size: 1.3rem;
      font-size: 1.2rem;
      line-height: 1;
      margin: 1rem 0;
      vertical-align: bottom;
    }
    &-hr {
      height: 2px;
      width: 25px;
      margin: 0 auto 3vh auto; // margin: -5px auto 10px auto;
      background: #fff;
    }
    &-btn {
      border: 1px solid #fff;
      font-size: 0.9rem;
      color: #fff;
      &:hover {
        color: $blue; 
        transition: 0.3s ease-out;
        background: #fff;
      }
    }
    $content-width: 75%;
    .feature {
      margin: 3vh auto;

      &-name {
        // margin: 3vh auto;
        position: relative;
        h4 {
          position: relative;
          font-weight: bold;
          display: inline;
          background: #fff;
          padding: 0 3%;
          z-index: 4;
        }
        &::after {
          z-index: 2;
          width: $content-width;
          height: 1px;
          background: #ddd;
          content: "";
          display: block;
          @include HVcenter();
        }
      }
      &-item {
        font-size: 0.9rem;
        display: flex;
        justify-content: space-between;
        width: $content-width;
        border-radius: 3px;
        padding: 1vh 0; // padding: 1vh 2vh;
        margin: 1vh auto;
        border: 1px solid #fff;
        position: relative;
        &:hover {
          cursor: pointer; // background: #f1f1f1;
          // border-bottom: 1px solid #ccc;
          // border-bottom: 1px solid $blue;
          // box-sizing: border-box;
          // padding: 0 1rem;
        }
      }
      &_disable {
        color: #ddd;
      }
    }
    &:hover {
      // position: relative;
      // transform: scale(1.05, 1.05);
      // border: 1px solid rgba(6, 20, 31, .2); // top: -20px;
      border: 1px solid rgba(104, 108, 255, 1);
      box-shadow: 0px 4px 14px 2px rgba(104, 108, 255, 0.35);
    }
    &:hover .row2-item-header {
      //background: radial-gradient($blue, $blue-deep);
      transition: 0.3s ease-in;
    }
    &:hover .row2-item-btn {
      transition: 0.3s ease-out;
      background: #fff;
      color: #686cff;
    }
  }
}
.popup {
  position: absolute;
  padding: 10px 1rem;
  bottom: calc(2vh + 1rem);
  z-index: 999;
  box-shadow: -10px 5px 40px rgba(57, 63, 68, 0.3);
  background: #fff;
  border-radius: 3px;
  visibility: hidden;
  border: 1px solid #eee; // display: none;
  &::after {
    $width: 6px;
    @include trangle($width, $width, #fff);
    bottom: -$width * 2;
    left: $width;
  }
  &-show {
    // display: block;
    visibility: visible;
  }
}
</style>
