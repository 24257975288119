var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('section',{staticClass:"section row2",on:{"scroll":function($event){return _vm.console.log('gundaosi')}}},[_c('div',{staticClass:"section-title"},[_c('h1',{staticClass:"section-title_black",domProps:{"innerHTML":_vm._s(_vm.justHas2Version ? '版本服务' : '版本服务')}}),_c('p',{staticClass:"section-subtitle_black"},[_vm._v(_vm._s(_vm.subTitle))])]),_c('div',{staticClass:"row-wrap"},_vm._l((_vm.currVersion.versionArr),function(item,index){return _c('div',{key:index,staticClass:"row2-item",class:_vm.justHas2Version ? 'version-2-item' : 'version-3-item'},[_c('div',{staticClass:"row2-item-header"},[_c('h3',{staticClass:"row2-item-title"},[_vm._v(_vm._s(item))]),_c('div',{staticClass:"row2-item-hr"}),_c('button',{staticClass:"primary-btn row2-item-btn",on:{"click":function($event){return _vm.showTryPopup(item)}}},[_vm._v(" 申请试用 ")])]),_vm._l((_vm.currVersion.content),function(cItem,cIndex){return _c('div',{key:cIndex,staticClass:"feature",class:{ feature_disable: !cItem.enable[index] }},[_c('div',{staticClass:"feature-name"},[_c('h4',[_vm._v(_vm._s(cItem.featureName))])]),_vm._l((cItem.content),function(ccItem,ccIndex){return _c('div',{key:ccIndex,staticClass:"feature-item",on:{"mouseover":function($event){(_vm.currentItemSubSubIndex = ccIndex),
                (_vm.currentItemSubIndex = cIndex),
                (_vm.currentItemIndex = index)},"mouseenter":function($event){(_vm.currentItemSubSubIndex = ccIndex),
                (_vm.currentItemSubIndex = cIndex),
                (_vm.currentItemIndex = index)},"mouseout":function($event){(_vm.currentItemSubSubIndex = null),
                (_vm.currentItemSubIndex = null),
                (_vm.currentItemIndex = null)}}},[_c('span',[_vm._v(_vm._s(ccItem.name))]),(ccItem.chooseDesc)?_c('span',[_vm._v(_vm._s(ccItem.chooseDesc))]):_vm._e(),(!ccItem.chooseDesc)?_c('span',{},[_vm._v("✔")]):_vm._e(),(ccItem.memo)?_c('div',{staticClass:"popup",class:{
                'popup-show':
                  _vm.currentItemSubSubIndex == ccIndex &&
                  _vm.currentItemSubIndex == cIndex &&
                  _vm.currentItemIndex == index,
              }},[_vm._v(" "+_vm._s(ccItem.memo)+" ")]):_vm._e()])})],2)})],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }