import router from "@/router/router.js";
import store from '../store.js' 
router.beforeEach((to, from, next) => {
	//   next("/home");
    store.commit("SET_IS_SCROLL_AT_TOP", true)
	window.scrollTo(0, 0); //路由切换，滚动条自动回到顶部
    // 同时重置导航条样式
    next();
    //   if (to.path === "/home") {
    //     //如果是从/home来的，说明是第一次进我们的网站
    //     next();
    //   } else {
    //     //不是从home页面进来的，说明已经进来并且浏览过页面了，这样写就能在手动刷新当前页之后仍然跳回当前页
    //     next({
    //       path: to.path
    //     });
    //   }
});