var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:{ isScrollAtTop: !_vm.isScrollAtTop || _vm.hoverMenu !== '' || _vm.isMobile },on:{"mouseleave":function($event){_vm.hoverMenu = ''}}},[(!_vm.isMobile)?[_c('div',{ref:"header",staticClass:"header flex-middle"},[_vm._m(0),_c('div',{staticClass:"flex-middle"},_vm._l((_vm.menuList),function(i,l){return _c('div',{key:l,staticClass:"item",on:{"mouseleave":function($event){i.show = false},"mouseover":function($event){(_vm.hoverMenu = l), (i.show = true)}}},[(_vm.hoverMenu !== '')?_c('p',{staticClass:"title",class:{ active: _vm.hoverMenu === l },on:{"click":function($event){return _vm.goDetail(i.path)}}},[_vm._v(" "+_vm._s(i.name)+" ")]):_c('p',{staticClass:"title",class:{ active: _vm.activeMenu === l },on:{"click":function($event){return _vm.goDetail(i.path)}}},[_vm._v(" "+_vm._s(i.name)+" ")]),(i.children && i.show)?_c('div',{staticClass:"child"},[_c('div',{staticClass:"childNav"},_vm._l((i.children),function(j,k){return _c('div',{key:k,staticClass:"i",class:{
                  active:
                    (_vm.activeMenu === l && _vm.activeSubMenu === k) ||
                    _vm.hoverSubMenu === k,
                  ddd: l == 2,
                },on:{"mouseover":function($event){_vm.hoverSubMenu = k},"mouseleave":function($event){_vm.hoverSubMenu = ''},"click":function($event){$event.stopPropagation();return _vm.goDetail(j.path)}}},[_c('div',{staticClass:"img",style:({
                    backgroundImage: `url(${
                      _vm.activeMenu === l && _vm.activeSubMenu === k
                        ? j.iconsPath
                        : _vm.hoverSubMenu === k
                        ? j.iconsPath
                        : j.iconPath
                    })`,
                  })}),_c('p',[_vm._v(_vm._s(j.name))])])}),0)]):_vm._e()])}),0),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.showTryPopup()}}},[_vm._v("申请试用")]),_vm._m(1)])]:[_c('div',{ref:"header",staticClass:"header mobile flex-center"},[_c('img',{staticClass:"navbtn",attrs:{"src":require("@/assets/img/nav1.png"),"alt":""},on:{"click":function($event){_vm.navWrap = !_vm.navWrap}}}),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo-b-240.png"),"alt":"logo"},on:{"click":function($event){return _vm.goDetail('/home')}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navWrap),expression:"navWrap"}],staticClass:"navWrap",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.navWrap = !_vm.navWrap}}}),_c('div',{staticClass:"navMain",class:{ active: _vm.navWrap }},[_c('ul',_vm._l((_vm.menuList),function(i,l){return _c('li',{key:l},[_c('p',{staticClass:"tit flex-middle",on:{"click":function($event){return _vm.goDetail(i.path, l)}}},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(i.name))]),(i.children)?_c('img',{class:{ active: !i.show },attrs:{"src":require("@/assets/img/down.png"),"alt":""}}):_vm._e()]),(i.children && i.show)?_c('ul',_vm._l((i.children),function(k,p){return _c('li',{key:p},[_c('p',{on:{"click":function($event){return _vm.goDetail(k.path)}}},[_vm._v(_vm._s(k.name))])])}),0):_vm._e()])}),0)])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo flex-middle flex-1"},[_c('div',{staticClass:"logo-item"}),_c('div',{staticClass:"name"},[_vm._v("眺望者科技")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tel"},[_c('p',[_vm._v("联系电话")]),_c('p',[_vm._v("020-28108209")])])
}]

export { render, staticRenderFns }