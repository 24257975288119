<template>
  <!-- :class="[isScrollAtTop ? '' : 'animation-banner-collapse']" -->
  <section>
    <section v-if="isMobile" class="hidden" style="width: 100%">
      <img :src="data.mobile" style="max-width: 100%" />
    </section>

    <section
      v-else-if="!data.type"
      class="banner"
      :style="{ backgroundImage: 'url(' + data.bgUrl + ')' }"
    >
      <div class="banner-content">
        <h1
          class="banner-title"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          {{ data.title }}
        </h1>
        <!-- <p
          class="banner-desc"
          v-for="(item, index) in data.subTitle"
          :key="index"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          {{ item }}
        </p> -->
        <p
          class="banner-desc"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          {{ data.subTitle.join("") }}
        </p>
        <div
          class="banner-tagbox"
          :style="{ width: data.tagWidth }"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          <div
            class="banner-tag"
            v-for="(item, index) in data.tagList"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </section>

    <!-- 中医的bannner -->
    <section
      v-else-if="data.type == 'zymzglxt'"
      class="banner_zy zymzglxt"
      :style="{ backgroundImage: 'url(' + data.bgUrl + ')' }"
    >
      <div class="banner_zy-content">
        <h1
          class="banner_zy-title"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          {{ data.title }}
        </h1>
        <p
          class="banner_zy-desc"
          v-for="(item, index) in data.subTitle"
          :key="index"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          {{ item }}
        </p>
        <div
          class="banner_zy-tagbox"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          <div
            class="banner-tag"
            v-for="(item, index) in data.tagList"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </section>
    <!-- 健康管理系统的bannner -->
    <section
      v-else-if="data.type == 'jkglxt'"
      class="banner_jkgl jkglxt"
      :style="{ backgroundImage: 'url(' + data.bgUrl + ')' }"
    >
      <div class="banner_jkgl-content">
        <h1
          class="banner_jkgl-title"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          {{ data.title }}
        </h1>
        <div
          class="banner_jkgl-desc"
          v-for="(item, index) in data.subTitle"
          :key="index"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          {{ item }}
        </div>
        <br />
        <div
          class="banner_jkgl-tagbox"
          :class="[isScrollAtTop ? 'animation-rightin2' : 'animation-leftin2']"
        >
          <div
            class="banner_jkgl-tag"
            v-for="(item, index) in data.tagList"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      isScrollAtTop: true,
      isMobile: this.getIsMobile(),
    };
  },
  props: {
    data: {
      title: "综合门诊管理系统",
      subTitle: [],
      tagList: [],
      tagWidth: 100,
    },
  },
  computed: {
    getIsScrollAtTop() {
      return this.$store.state.isScrollAtTop;
    },
  },
  watch: {
    getIsScrollAtTop(newVal, oldVal) {
      //监听vuex里面isScrollAtTop的变化，动态赋值
      this.isScrollAtTop = newVal;
    },
  },
  methods: {},
  created() {},
  mounted() {
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.home {
  @include responsive-bg();
  width: 100%;
  color: #fff;
  position: relative;
  height: $banner-height;
  &-title {
    position: absolute;
    top: $banner-title-top;
    @include Hcenter();
  } // @include banner(#ffffff, $blue);
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
$row-content-top-offset: 110px; // 本页面公用的动画
@mixin banner($font-color, $font-color_zy) {
  .banner_jkgl {
    width: 100%;
    color: $font-color;
    position: relative;
    height: $banner-height;
    overflow: hidden;
    text-align: center;
    @include responsive-bg();
    &-content {
      @include Hcenter();
      width: 100%;
      top: 15vh;
      // width: 100% - $page-margin-left-right; //为了让css3变量兼容ie11，详情戳这里 https://stackoverflow.com/questions/45955538/workaround-for-css-variables-in-ie#
      // width: calc(100% - var(--page-margin-left-right));
    }
    &-title {
      // white-space: nowrap;
      font-size: 2.1rem;
      margin-bottom: 0;
    }
    &-desc {
      display: inline-block;
      font-size: 1.2rem;
      width: $page-margin-left-right * 3;
      width: calc(var(--page-margin-left-right) * 3);
    }
    &-tagbox {
      display: inline-block; // width: calc(var(--page-margin-left-right) * 3);
      margin-top: 0;
    }
    &-tag {
      display: inline-block;
      padding: 0.35rem 0.8rem;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba($font-color, 0.8);
      white-space: nowrap; //强制不换行
      margin: 1vh 20px 1vh 0;
    }
  }
  .banner {
    width: 100%;
    color: $font-color;
    position: relative;
    height: $banner-height;
    overflow: hidden;
    @include responsive-bg();
    &-content {
      @include Vcenter();
      left: $page-margin-left-right;
      left: 16%;
      @include for-laptop_sm() {
        left: calc(
          var(--page-margin-left-right) + var(--page-margin-left-right) / 0.8
        );
      } // @media screen and (max-width: var(--pc-sm)) {
      // 	left: calc(var(--page-margin-left-right) + var(--page-margin-left-right) / 0.8);
      // }
      width: 100% - $page-margin-left-right;
      width: calc(100% - var(--page-margin-left-right));
    }
    &-title {
      white-space: nowrap;
      font-size: 2.1rem;
    }
    &-desc {
      font-size: 1.2rem;
      max-width: 350px;
      line-height: 2;
      // width: $page-margin-left-right * 3;
      // width: calc(var(--page-margin-left-right) * 3);
      @include for-laptop_sm() {
        width: 43%;
      }
    }
    &-tagbox {
      margin-top: 15px;
      // width: calc(var(--page-margin-left-right) * 2.2);
      @include for-laptop_sm() {
        width: 43%;
      }
    }
    &-tag {
      display: inline-block;
      padding: 0.35rem 0.8rem;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba($font-color, 0.8);
      white-space: nowrap; //强制不换行
      margin: 1vh 20px 1vh 0;
    }
  }
  .banner_zy {
    text-align: left;
    color: $font-color;
    width: 100%;
    position: relative;
    height: $banner-height;
    overflow: hidden;
    @include responsive-bg();
    &-content {
      @include Vcenter();
      // max-width: 100%;
      padding: 0 40px 0 10px;
      right: 14%;
      // right: $page-margin-left-right;
      // right: calc(var(--page-margin-left-right));
      @media screen and (max-width: var(--pc-sm)) {
        right: calc(
          var(--page-margin-left-right) + var(--page-margin-left-right) / 0.8
        );
      }
    }
    &-title {
      font-weight: bold;
      white-space: nowrap;
      font-size: 2.1rem;
    }
    &-desc {
      font-size: 1.2rem;
    }
    &-tagbox {
      display: inline-block;
      right: 0;
      padding-bottom: 10vh;
      max-width: 400px;
      @include for-laptop_sm() {
        // width: 43%;
      }
    }
    &-tag {
      display: inline-block;
      padding: 0.35rem 0.8rem;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba($font-color_zy, 0.8);
      white-space: nowrap; //强制不换行
      margin: 1vh 0 1vh 20px;
    }
  }
} // banner图
@include banner(#ffffff, $blue);
</style>
